<template>
  <div class="content4">
    <div class="link-package flex justify-between items-center">
      <div>
        <h3>الربط مع Woo Commerce :</h3>
        <p>عن طريق الإضافة الرسيمة لموقع ووكوميرس علي متجر ورد بريس.</p>
        <div class="flex items-center">
          <button class="link-package-btn1">
            <a
              class="flex items-center"
              href="https://cdn.turbo-eg.com/turbo-integration-with-woocommerce.pdf"
            >
              <svg
                class="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="14.927"
                height="17.415"
                viewBox="0 0 14.927 17.415"
              >
                <path
                  id="Icon_metro-file-pdf"
                  data-name="Icon metro-file-pdf"
                  d="M16.837,5.9a2.261,2.261,0,0,1,.467.739,2.243,2.243,0,0,1,.194.855v11.2a.929.929,0,0,1-.933.933H3.5a.929.929,0,0,1-.933-.933V3.136A.929.929,0,0,1,3.5,2.2h8.708a2.243,2.243,0,0,1,.855.194,2.261,2.261,0,0,1,.739.466ZM12.522,3.525V7.179h3.654a1.062,1.062,0,0,0-.214-.4L12.921,3.739a1.061,1.061,0,0,0-.4-.214Zm3.732,14.85V8.423H12.211a.929.929,0,0,1-.933-.933V3.447H3.815V18.375h12.44Zm-5-5.763a7.4,7.4,0,0,0,.816.544,9.644,9.644,0,0,1,1.137-.068q1.429,0,1.72.476a.47.47,0,0,1,.019.505.028.028,0,0,1-.01.019l-.019.019v.01q-.058.369-.69.369a4.009,4.009,0,0,1-1.118-.194,7.086,7.086,0,0,1-1.263-.515,17.5,17.5,0,0,0-3.81.807Q6.555,17.131,5.69,17.131a.565.565,0,0,1-.272-.068l-.233-.117q-.01-.01-.058-.049a.4.4,0,0,1-.058-.35,2.111,2.111,0,0,1,.544-.889A4.7,4.7,0,0,1,6.9,14.721a.142.142,0,0,1,.224.058.056.056,0,0,1,.019.039q.505-.826,1.04-1.915a14.833,14.833,0,0,0,1.011-2.546,7.856,7.856,0,0,1-.3-1.55,3.779,3.779,0,0,1,.063-1.239q.107-.389.408-.389h.214a.41.41,0,0,1,.34.146.776.776,0,0,1,.087.661.211.211,0,0,1-.039.078.253.253,0,0,1,.01.078v.292A12.726,12.726,0,0,1,9.84,10.3a4.917,4.917,0,0,0,1.419,2.313Zm-5.6,3.994a4.291,4.291,0,0,0,1.331-1.536,5.54,5.54,0,0,0-.85.816A3.23,3.23,0,0,0,5.661,16.606ZM9.529,7.665A2.89,2.89,0,0,0,9.51,8.948q.01-.068.068-.428,0-.029.068-.418a.219.219,0,0,1,.039-.078.028.028,0,0,1-.01-.019.02.02,0,0,0,0-.015.02.02,0,0,1,0-.015.559.559,0,0,0-.126-.35.028.028,0,0,1-.01.019v.019ZM8.324,14.089a14.255,14.255,0,0,1,2.76-.787,1.466,1.466,0,0,1-.126-.092,1.741,1.741,0,0,1-.155-.131,5.148,5.148,0,0,1-1.234-1.71,12.987,12.987,0,0,1-.807,1.915q-.292.544-.437.807Zm6.278-.155a2.326,2.326,0,0,0-1.361-.233,3.676,3.676,0,0,0,1.205.272.943.943,0,0,0,.175-.01q0-.01-.019-.029Z"
                  transform="translate(-2.571 -2.203)"
                  fill="#212121"
                />
              </svg>
              ملف شرح الاضافة
            </a>
          </button>
          <button class="link-package-btn2">
            <a href="https://downloads.wordpress.org/plugin/turbo.zip"
              >تحميل الاضافة</a
            >
          </button>
        </div>
      </div>
      <img src="@/assets/images/elements/Image 4.png" />
    </div>

    <div class="link-package shopify flex justify-between items-center">
      <div>
        <h3>الربط مع Shopify :</h3>
        <p>عن طريق الاضافة الرسمية لمنصة التجارة الإلكترونية شوبيفاي.</p>
        <div>
          <button class="link-package-btn1">ستكون متاحة قريباً</button>
        </div>
      </div>
      <img src="@/assets/images/elements/Image 5.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "linkWithPlugins",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_setting.scss";
</style>
